@import "assets/fonts/roboto-slab/roboto-slab.css";
@import "assets/fonts/source-sans/SourceSans3.css";
@import "assets/fonts/unison-pro/unison-pro.css";
@import "assets/fonts/robo/robo.css";
@import "assets/libs/FontAwesome_v6.5.2/css/all.css";
@import "assets/libs/FontAwesome_v6.5.2/css/sharp-regular.css";
@import "assets/libs/FontAwesome_v6.5.2/css/sharp-solid.css";

/*
font-family: 'RobotoSlab', sans-serif;
font-family: 'UnisonPro', sans-serif;
*/

:root {
  --swiper-theme-color: #002c6b;
  --swiper-pagination-bullet-width: 12px;
  --swiper-pagination-bullet-height: 12px;
  --swiper-pagination-bullet-horizontal-gap: 2px;
  --swiper-pagination-bottom: 0;
  --swiper-pagination-bullet-inactive-color: #cbd5e1;
  --swiper-pagination-bullet-inactive-opacity: 1;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    transition: all 0.3s ease;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background-color: transparent;
    transition: all 0.3s ease;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 100vh;
    transition: all 0.3s ease;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
  }

  .scrollbar-0::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .scrollbar-1::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
  }

  .scrollbar-1::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.2);
  }

  .scrollbar-1::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.5);
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .conic-gradient {
    background: conic-gradient(from -90deg, #ec2d40, #b21020, #b21020, #b21020);
  }
}

*,
*:focus {
  outline: none;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "SourceSans3", sans-serif;
}

.lazy-load-image-background {
  display: block !important;
  width: 100%;
}

swiper-slide {
  height: auto !important;
  display: flex;
}

footer {
  /* background: url(../src/assets/images/footer_bg.jpg); */
  background-size: cover;
  background-position: center;
  z-index: 1;
}

footer::after {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #9d467e;
  content: "";
  z-index: -1;
}

.copyright::after {
  position: absolute;
  left: 100%;
  top: 0;
  border-right: 40px solid transparent;
  border-bottom: 72px solid #9d467e;
  content: "";
}
.abt {
  /* background: url(../src/assets/images/abt.jpg); */
  background-repeat: no-repeat;
  background-position: center left;
  padding: 100px 0;
}

.testibg {
  /* background: url(../src/assets/images/testimonial_bg.png); */
  background-position: center center;
  padding: 100px 0;
  height: 100%;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  opacity: 0;
}
.leftdiv {
  /* background: url(../src/assets/images/steps-bg.png); */
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}
.gallery::before {
  position: absolute;
  top: 20px;
  left: 15px;
  bottom: 20px;
  right: 15px;
  background: rgba(0, 0, 0, 0.9);
  transform: perspective(400px) rotateX(90deg) scaleY(0.5);
  transform-origin: center;
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.5s;
  transition-property: all;
  content: "";
  z-index: 1;
}
.gallery:hover::before {
  transform: perspective(400px) rotateX(0deg) scaleY(1);
  transition-delay: 0.1s;
  transition-timing-function: ease-in-out;
  transition-duration: 0.7s;
  transition-property: all;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-fadeInLeft {
  animation: fadeInLeft 1s ease-in-out;
}

.animate-fadeInRight {
  animation: fadeInRight 1s ease-in-out;
}

/* Define custom keyframes animation */
@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Apply animation to the heading */
.text-victoria {
  animation: pulse 2s infinite;
}

.expert {
  background: url("https://m.media-amazon.com/images/I/71ymVEtZakL.jpg");
  background-position: center;
  background-size: cover;
}

.aboutEndBanner {
  background: url(../src/assets/aboutBannerEnd.jpeg);
  background-position: center;
  background-size: cover;
}
.contactEndBanner {
  background: url(../src/assets/contactEndbanner.jpeg);
  background-position: center;
  background-size: cover;
}

/* swiper js nevigation for category-item */

.category-swiper .swiper-button-prev {
  margin-left: -7px;
}
.category-swiper .swiper-button-next {
  margin-right: -7px;
}

.category-swiper .swiper-button-next::after,
.category-swiper .swiper-button-prev::after {
  color: white !important;
  font-size: 12px !important;
  background-color: #9d467e !important;
  min-height: 30px;
  min-width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  transition: 0.2s all ease;
}

.category-swiper .swiper-button-next:hover::after,
.category-swiper .swiper-button-prev:hover::after {
  background-color: #9d467e !important;
}

.serviceEndbanner {
  background: url("https://images.squarespace-cdn.com/content/v1/5e29ca2a630fb46e59749dcb/1594920468833-3VHK8MVL6ED6P0UQW4QB/1BAA57F7-B0A6-4A5D-ABA3-630841C8B19C-converted_Page_22.jpg");
  background-position: center;
  background-size: cover;
}
.productEndBanner {
  background: url(../src/assets/productEndBanner.jpg);
  background-position: center;
  background-size: cover;
}

/* .servlist{background: url(../src/assets/images/service_list.jpg); background-position: center; background-size: cover;} */
