@font-face {
  font-family: "UnisonPro";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(UnisonPro-Light.woff2) format("woff2");
}
@font-face {
  font-family: "UnisonPro";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url(UnisonPro-LightItalic.woff2) format("woff2");
}
@font-face {
  font-family: "UnisonPro";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(UnisonPro-Bold.woff2) format("woff2");
}
@font-face {
  font-family: "UnisonPro";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(UnisonPro-BoldItalic.woff2) format("woff2");
}
