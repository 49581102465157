@font-face {
  font-family: "RobotoSlab";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(RobotoSlab-Thin.woff2) format("woff2");
}
@font-face {
  font-family: "RobotoSlab";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(RobotoSlab-ExtraLight.woff2) format("woff2");
}
@font-face {
  font-family: "RobotoSlab";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(RobotoSlab-Light.woff2) format("woff2");
}
@font-face {
  font-family: "RobotoSlab";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(RobotoSlab-Regular.woff2) format("woff2");
}
@font-face {
  font-family: "RobotoSlab";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(RobotoSlab-Medium.woff2) format("woff2");
}
@font-face {
  font-family: "RobotoSlab";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url(RobotoSlab-SemiBold.woff2) format("woff2");
}
@font-face {
  font-family: "RobotoSlab";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(RobotoSlab-Bold.woff2) format("woff2");
}
@font-face {
  font-family: "RobotoSlab";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url(RobotoSlab-ExtraBold.woff2) format("woff2");
}
@font-face {
  font-family: "RobotoSlab";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(RobotoSlab-Black.woff2) format("woff2");
}
