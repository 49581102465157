@font-face {
  font-family: "SourceSans3";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url(SourceSans3-Regular.ttf) format("ttf");
}
@font-face {
  font-family: "SourceSans3";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url(SourceSans3-Medium.ttf) format("ttf");
}
@font-face {
  font-family: "SourceSans3";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(SourceSans3-SemiBold.ttf) format("ttf");
}
@font-face {
  font-family: "SourceSans3";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(SourceSans3-Bold.ttf) format("ttf");
}

